<template>
  <div class="home flex items-center flex-between">
    <div class="home-title flex home-titles items-center" @click="homeBuuton()">
      <div>
        <img
          src="https://cdn.bubbleplan.cn/static/home/logo.png"
          alt=""
          class="home-title-logo"
        />
      </div>
      <div style="margin-left: 24px">
        <div
          style="
            margin-top: 8px;
            color: #ffffff;
            font-size: 21px;
            line-height: 29.75px;
          "
        >
          泡泡计划
        </div>
        <div
          style="
            margin-top: 8px;
            color: #999999;
            font-size: 19px;
            line-height: 26.92px;
          "
        >
          Bubble Plan
        </div>
      </div>
      <img
        src="../../img/home/botton_bgc.png"
        alt=""
        class="home-title-button"
      />
    </div>
    <div class="home-type flex">
      <!-- <el-menu
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        :defaultActive="activeIndex"
        background-color="transparent"
        text-color="#ffffff"
        active-text-color="#DAFF00"
        ref="elMenu"
      >
        <el-menu-item index="4">NFT</el-menu-item>
        <el-submenu index="1">
          <template slot="title">游戏</template>
          <el-menu-item index="1-1" style="padding-left: 20px"
            >游戏下载</el-menu-item
          >
          <el-menu-item index="1-2" style="padding-left: 20px"
            >白皮书</el-menu-item
          >
          <el-menu-item index="1-3" style="padding-left: 20px"
            >游戏介绍</el-menu-item
          >
        </el-submenu>
        <el-menu-item index="2">市场</el-menu-item>
        <el-menu-item index="3">新闻</el-menu-item>
      </el-menu> -->

      <div
        v-for="(item, index) in typeList"
        :key="index"
        class="home-type-li"
        :class="
          typeIndex == index && index == 4
            ? 'pitchon1'
            : typeIndex == index
            ? 'pitchon'
            : ''
        "
        @click.stop="typeButton(item, index)"
      >
        <div>{{ item.name }}</div>
        <div class="diamond" v-if="item.secondLevel.length > 0"></div>
        <div
          v-if="item.secondLevel.length > 0"
          class="home-type-li-secondLevel"
        >
          <div
            v-for="(l, i) in item.secondLevel"
            :key="i"
            class="home-type-li-secondLevel-text"
            @click.stop="secondLevelButton(l)"
          >
            {{ l.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="home-user flex">
      <div
        class="home-user-exchange flex"
        @click="exchange()"
        v-if="suerInfo.userId"
      >
        <div class="home-user-exchange-text flex items-center">
          <img
            src="https://cdn.bubbleplan.cn/static/journalism/chip.png"
            alt=""
            class="home-user-exchange-text-img"
          />
          <div>{{ property.productNum ? property.productNum : 0 }}</div>
        </div>
        <div
          class="home-user-exchange-text flex items-center"
          style="margin-left: 10px"
        >
          <img
            src="https://cdn.bubbleplan.cn/m_bubble/home/integration.png"
            alt=""
            class="home-user-exchange-text-img1"
          />
          <div>{{ property.integrationNum ? property.integrationNum : 0 }}</div>
        </div>
        <div
          class="home-user-exchange-text flex items-center"
          style="margin-left: 10px"
        >
          <img
            src="https://cdn.bubbleplan.cn/m_bubble/commodity/crystal111.png"
            alt=""
            class="home-user-exchange-text-img1"
          />
          <div>
            {{ crystalNumData.productNum ? crystalNumData.productNum : 0 }}
          </div>
        </div>
        <div
          class="home-user-exchange-text flex items-center"
          style="margin-left: 10px"
        >
          <img
            src="https://cdn.bubbleplan.cn/m_bubble/activity/434324.png"
            alt=""
            class="home-user-exchange-text-img3"
          />
          <div>
            {{
              crystalNumData.integrationNum ? crystalNumData.integrationNum : 0
            }}
          </div>
        </div>
      </div>
      <div class="home-user-button" @click="loginButton()" v-if="suerInfo">
        <div v-if="suerInfo.userId" class="flex items-center flex-center">
          <img
            :src="suerInfo.headImg"
            alt=""
            style="
              width: 39px;
              height: 39px;
              border-radius: 50%;
              margin-right: 4px;
            "
          />
          <div style="font-size: 22px; color: #000">
            {{ suerInfo.name }}
          </div>
        </div>
        <div v-else>注册/登录</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      whiteNum: 0,
      ticketsNum: 0,
      activeIndex: "",
      typeIndex: null,
      secondLevelShow: false,
      typeList: [
        {
          name: "NFT",
          url: "/commodity/index",
          secondLevel: [],
        },
        {
          name: "游戏",
          secondLevel: [
            {
              name: "游戏下载",
              url: "/home/gamedownload",
            },
            {
              name: "白皮书",
              url: "/gameRelated/bubbleProject",
            },
            {
              name: "游戏介绍",
              url: "/gameRelated/gameIntroduction",
            },
          ],
        },
        {
          name: "市场",
          url: "/marketplace",
          secondLevel: [],
        },
        {
          name: "新闻",
          url: "/journalism/journalism",
          secondLevel: [],
        },
        {
          name: "疯狂派的秘密实验室",
          url: "/activity/interpreter/index",
          secondLevel: [],
        },
      ],
    };
  },
  mounted() {
    this.getOwnerNumByType();
    this.getOwnerNumByType5();
  },
  methods: {
    async getOwnerNumByType5() {
      const { data, code } = await this.$ajax.getOwnerNumByType({
        type: 5,
      });
      this.whiteNum = data;
      this.crystalNum();
    },
    async getOwnerNumByType() {
      const { data, code } = await this.$ajax.getOwnerNumByType({ type: 6 });
      this.ticketsInfo(data);

      // this.ticketsNum = data;
    },
    //查询晶体or晶体积分
    async crystalNum() {
      const { data, code } = await this.$ajax.crystalNum();
      if (code == 200) {
        this.usercrystalNumData(data);
      }
    },

    secondLevelButton(l) {
      this.typeIndex = 1;
      if (l.url) {
        this.$router.push(l.url);
        this.secondLevelShow = true;
      } else {
        this.secondLevelShow = false;
        this.$message({
          message: "暂未开放！",
          type: "warning",
        });
        return;
      }
    },
    typeButton(item, index) {
      this.secondLevelShow = false;
      this.typeIndex = index;
      if (item.url) {
        this.$router.push(item.url);
      } else {
        if (this.typeIndex != 1) {
          this.$message({
            message: "暂未开放！",
            type: "warning",
          });
          return;
        }
      }
    },
    journalism(type) {
      if (type == 10) {
        this.$router.push("/gameText/bubbleProject");
      }
    },
    homeBuuton() {
      this.typeIndex = null;
      this.$router.push("/home");
    },
    exchange() {
      this.typeIndex = null;
      this.$router.push("/exchange/index");
    },
    handleSelect(key) {
      console.log(key);
      if (key == 2) {
        this.$router.push("/marketplace");
      }
      if (key == "1-3-4") {
        this.$router.push("/gameText/modeIntroduction");
      } else if (key == "1-3-3") {
        this.$router.push("/gameText/gameplayIntroduction");
      } else if (key == "1-2") {
        this.$router.push("/gameRelated/bubbleProject");
      } else if (key == "4") {
        this.$router.push("/commodity/index");
      } else if (key == "3") {
        this.$router.push("/journalism/journalism");
      } else if (key == "1-2-1-1") {
        this.$router.push("/gameText/ethnic");
      } else if (key == "1-2-1-2") {
        this.$router.push("/gameText/talent");
      } else if (key == "1-2-1-3") {
        this.$router.push("/gameText/gameFunction");
      } else if (key == "1-2-1-4") {
        this.$router.push("/gameText/crazies");
      } else if (key == "1-2-3-1") {
        this.$router.push("/gameText/beta1");
      } else if (key == "1-2-3-2") {
        this.$router.push("/gameText/arena");
      } else if (key == "1-2-3-3") {
        this.$router.push("/gameText/missionsystem");
      } else if (key == "1-1") {
        this.$router.push("/home/gamedownload");
      } else if (key == "1-3") {
        this.$router.push("/gameRelated/gameIntroduction");
      } else {
        this.$message({
          message: "暂未开放！",
          type: "warning",
        });
        return;
      }
    },
    loginButton() {
      this.typeIndex = null;
      if (this.username.userId) {
        if (this.$route.path !== "/user") {
          this.$router.push("/user");
        }
      } else {
        if (this.$route.path !== "/login/login") {
          this.$router.push("/login/login");
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  padding: 0 67px 0 79px;
  height: 94px;
  background: #10061e;
  // border-bottom: 2px solid #daff00;

  &-title {
    color: #ffffff;
    font-size: 26.93px;
    font-style: normal;
    // font-weight: 700;
    line-height: 38.16px;
    position: relative;
    padding-top: 18px;
    &-logo {
      width: 82px;
      height: 82px;
      margin-top: 4px;
      z-index: 9999;
      position: relative;
    }
    &-button {
      position: absolute;
      width: 120px;
      height: 30px;
      left: -19px;
      top: 94px;
      z-index: 99;
    }
  }

  .home-type {
    // width: 700px;
    &-li:hover {
      cursor: pointer;
    }
    &-li:hover .home-type-li-secondLevel {
      display: block !important;
    }
    &-li:nth-child(5) {
      width: 280px;
    }
    .pitchon1 {
      width: 300px;
      // min-width: 169px;
      height: 89px !important;
      background: url("https://cdn.bubbleplan.cn/static/home/Group100582x.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      color: #cbee00;
      text-shadow: -6px -6px 0 #00000033, 6px -6px 0 #00000033,
        -6px 6px 0 #00000033, 6px 6px 0 #00000033;
      /* 模拟描边效果 */
      // text-shadow: 6px 6px 0 #00000033;
      font-size: 28px;
      font-family: "qiantuhouheiti";
      line-height: 89px;
      // padding: 0 10px;
    }
    &-li {
      width: 145px;
      height: 89px;
      color: #ffffff;
      line-height: 89px;
      text-align: center;
      font-size: 24px;
      position: relative;
      .diamond {
        width: 145px;
        height: 100px;
        background: transparent;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 90;
      }
      .diamond:hover + .home-type-li-secondLevel {
        display: block !important;
      }
      &-secondLevel {
        display: none;
        width: 199px;
        height: 208px;
        background: url("https://cdn.bubbleplan.cn/static/home/Group100642x.png")
          no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 93px;
        z-index: 999;
        color: #ffffff;
        font-size: 21px;
        &-text:hover {
          cursor: pointer;
          color: #daff00;
          font-size: 29px;
          text-shadow: -2px -2px 0 #d32ae1cc, 2px -2px 0 #d32ae1cc,
            -2px 2px 0 #d32ae1cc, 2px 2px 0 #d32ae1cc;
          /* 模拟描边效果 */
          font-family: "qiantuhouheiti";
        }
        &-text {
          height: 30%;
          font-weight: 400;
          font-family: "Microsoft YaHei UI";
        }
      }
    }
    .pitchon {
      // min-width: 169px;
      height: 89px !important;
      background: url("https://cdn.bubbleplan.cn/static/home/Group100582x.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      color: #cbee00;
      text-shadow: -6px -6px 0 #00000033, 6px -6px 0 #00000033,
        -6px 6px 0 #00000033, 6px 6px 0 #00000033;
      /* 模拟描边效果 */
      // text-shadow: 6px 6px 0 #00000033;
      font-size: 38px;
      font-family: "qiantuhouheiti";
      line-height: 89px;
      padding: 0 10px;
    }
    :deep(.el-menu-item.is-active) {
      width: 199px;
      height: 89px !important;
      background: url("https://cdn.bubbleplan.cn/static/home/Group100582x.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      color: #cbee00;
      text-shadow: -6px -6px 0 #00000033, 6px -6px 0 #00000033,
        -6px 6px 0 #00000033, 6px 6px 0 #00000033;
      /* 模拟描边效果 */
      // text-shadow: 6px 6px 0 #00000033;
      font-size: 38px;
      font-family: "qiantuhouheiti";
      line-height: 89px;
    }

    :deep(.el-menu.el-menu--horizontal) {
      border-bottom: 0 !important;
    }

    :deep(.el-submenu__title) {
      font-size: 26px;
      background-color: transparent !important;
    }
    :deep(.el-submenu) {
      height: 89px !important;
      line-height: 89px !important;
    }
    :deep(.el-menu-item) {
      height: 89px !important;
      line-height: 89px !important;
      font-size: 26px !important;

      padding: 0 70px !important;
      // padding-left: 16px !important;
      background-color: transparent !important;
      border-bottom-color: transparent !important;
    }

    :deep(.el-submenu__title) {
      padding: 0 70px;
      height: 89px !important;
      line-height: 89px !important;
      border-bottom-color: transparent !important;
    }
  }

  .home-user {
    .home-user-button {
      width: 200px;
      height: 60px;
      border-radius: 6.73px;
      background: #daff00;
      text-align: center;
      line-height: 60px;
      font-size: 24px;
      color: #10061e;
    }
    .home-user-button:hover {
      cursor: pointer;
    }
    .home-user-exchange {
      margin-right: 50px;

      &-text {
        color: rgb(218, 255, 0);
        font-size: 24px;
        line-height: 60px;
        font-weight: 400;
      }
    }
    .home-user-exchange:hover {
      cursor: pointer;
    }
  }
}

:deep(.el-menu--horizontal) {
  background: #10061e !important;
}

:deep(.el-menu-item:hover) {
  // color: #daff00 !important;
  background: #262626cc !important;
}

:deep(.el-submenu__title:hover) {
  color: #daff00 !important;

  background: #262626cc !important;
}

:deep(.el-submenu__title i) {
  background: url("../../img/home/downarrow.png") no-repeat;
  background-size: 24px 24px;
  color: transparent !important;
}

:deep(.el-menu--popup-bottom-start) {
  padding-left: 16px;
}

:deep(.el-submenu__icon-arrow) {
  width: 24px;
  height: 24px;
}
.home-titles:hover {
  cursor: pointer;
}
.home-user-exchange-text-img {
  width: 42px;
  height: 35px;
  margin-right: 6.7px;
}
.home-user-exchange-text-img1 {
  width: 35.34px;
  height: 28.25px;
  margin-right: 6.7px;
}
.home-user-exchange-text-img3 {
  width: 35px;
  height: 35px;
}
.home-user-exchange-text-img2 {
  // width: 35.34px;
  width: 18px;
  height: 28.25px;
  margin-right: 6.7px;
}
:deep(.el-menu--popup) {
  width: 199px;
  height: 208px;
  background: url("https://cdn.bubbleplan.cn/static/home/Group100642x.png")
    no-repeat;
  background-size: 100% 100%;
}
</style>
