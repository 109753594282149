import axios from "axios";
import state from "../store/index.js";
import router from "../router/index";
// 封装Axios 代码
// Loading
// 操作提示

// const baseURL = "http://192.168.3.89:7070/"; // cxm
// const baseURL = "https://testserver.bubbleplan.cn/"; // cs 
const baseURL = "https://server.bubbleplan.cn/"; // 正式服

const AUTH_TOKEN = "";
axios.defaults.baseURL = baseURL; // 使用反向代理就不需要使用axios基路径
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

import { Toast, Notify } from "vant";
import { Message } from "element-ui";
export function ShowLoading(msg) {
  msg = msg || "加载中...";
  Toast.clear();
  // Toast.loading({
  //   message: msg,
  //   forbidClick: true,
  //   loadingType: 'spinner',
  // });
}

export function ShowSuccess(msg) {
  msg = msg || "请求成功";
  // Toast.clear();
  // Toast.success(msg);
}

export function ShowError(msg) {
  msg = msg || "失败";
  // Notify.clear()
  // Notify({
  //   message: msg,
  //   color: '#ad0000',
  //   background: '#ffe1e1',
  // });
}
// 拦截器   Interceptors

// 请求发送的拦截器  request
axios.interceptors.request.use(
  function (config) {
    // 成功
    // loading
    ShowLoading();
    return config;
  },
  function (error) {
    // 失败
    ShowError("请求发送失败");
    return Promise.reject(error);
  }
);

// 请求响应的拦截器  response
axios.interceptors.response.use(
  function (response) {
    if (response.data.msg) {
      // 成功
      if (response.data.code == 200) {
        Message({
          message: response.data.msg,
          type: 'success',
        });
      } else {
        if (response.data.msg == '有用户正在操作该笔订单，请稍后重试！') {

        } else {
          Message({
            message: response.data.msg,
            type: "warning",
          });
        }

      }
    }
    if (response.data.code == 510) {
      localStorage.removeItem("user_info");
      state.commit('usernameUser', {})
      state.commit('userProperty', {})
      state.commit('userSuerInfo', {})
      router.push("/login/login");
    }
    return response;
  },
  function (error) {
    // 失败
    // console.log(error);
    // if (response.data.msg) {
    //   Message.error({
    //     message: response.data.msg,
    //   });
    // }
    Message.error({
      message: '网络异常稍后再试。。。',
    });
    return Promise.reject(error);
  }
);
// if (state.state.username.token) {
//   axios.defaults.headers.token = state.state.username.token;
// }
// axios 二次封装

export function get(url, params, headers) {
  return new Promise(function (resolve, reject) {
    axios
      .get(url, {
        params, // 请求参数
        headers: {
          token: state.state.username.token,
        }, // 请求头
      })
      .then((res) => {
        // console.log(res)
        resolve(res.data); // res.data 当做成功回调的参数返回出去
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function post(url, body, headers) {
  return new Promise(function (resolve, reject) {
    // console.log(headers)
    axios
      .post(url, body, {
        headers: {
          token: state.state.username.token,
        }, // 请求头
      })
      .then((res) => {
        // console.log(res)
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function patch(url, params, headers) {
  return new Promise(function (resolve, reject) {
    axios({
      url,
      method: "patch",
      data: params,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function Delete(url, params, headers) {
  return new Promise(function (resolve, reject) {
    axios({
      url,
      method: "delete",
      data: params,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export { axios };
