<template>
  <div>
    <el-container>
      <el-header style="padding: 0">
        <homew />
      </el-header>
      <el-main style="padding: 0">
        <!-- <router-view v-if="routerShow" /> -->
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"> </router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"> </router-view>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import homew from "./components/homew.vue";
export default {
  components: {
    homew,
  },
  data() {
    return {
      routerShow: true,
    };
  },
};
</script>

<style lang="scss">
.el-header {
  height: 94px !important;
}
</style>
