import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import createPersistedState from "vuex-persistedstate";

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    username: {}, //用户信息
    suerInfo: {}, //用户
    property: {}, //金币/积分
    crystalNumData: {},//晶体or晶体积分
    tickets: 0
  },
  mutations: {
    usernameUser(state, payload) {
      state.username = payload;
    },
    userProperty(state, payload) {
      state.property = payload;
    },
    usercrystalNumData(state, payload) {
      state.crystalNumData = payload;
    },
    userSuerInfo(state, payload) {
      state.suerInfo = payload;
    },
    ticketsInfo(state, payload) {
      state.tickets = payload;
    },
  },
  actions: {},

  modules: {},
});
