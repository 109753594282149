import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(Element, { size: "small", zIndex: 3000 });
import { Toast, Notify } from "vant";
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

Vue.config.productionTip = false;

// 全局的公共样式
import "@/styles/index.scss";

// 全局混入
import "@/utils/mixin";
// import './utils/rem.JS'
// 引入vant 插件
import vant from "vant";
import "vant/lib/index.css";
import { ImagePreview } from "vant";
Vue.use(ImagePreview);

// 淘宝适配
import "lib-flexible";

// axios
import { axios } from "@/api/request";
Vue.prototype.$axios = axios;




//隐藏打印
// console.log = () => { }



// // 全局注册组件
// import "@/components";

// 挂载正则
// 挂载正则对象
import { reg } from "@/utils/validate";
Vue.prototype.$reg = reg;
// 全局的ajax
import { ajax } from "@/api/index";
Vue.prototype.$ajax = ajax;
router.options.scrollBehavior = () => ({ y: 0 });
Vue.use(vant);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
