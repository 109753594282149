<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {},
  mounted() {
    this.getLast();
  },
  methods: {
    async getLast() {
      const { data, code } = await this.$ajax.getLast();
      let versionNumber = localStorage.getItem("versionNumber");
      if (data.versionNumber != versionNumber) {
        localStorage.setItem("versionNumber", data.versionNumber);
        this.clearServiceWorkerCache();
        // self.addEventListener("activate", function (event) {
        //   console.log(event, 12121121);
        //   event.waitUntil(
        //     caches.keys().then(function (cacheNames) {
        //       return Promise.all(
        //         cacheNames.map(function (cacheName) {
        //           console.log(cacheNames);
        //           return caches.delete(cacheName);
        //         })
        //       );
        //     })
        //   );
        // });

        setTimeout(() => {
          window.location.reload(true);
        }, 500);
      } else {
        this.redirectToProject();
        // this.clearServiceWorkercCache();
      }
    },
    async clearServiceWorkerCache() {
      if ("caches" in window) {
        const cacheNames = await caches.keys();
        console.log(cacheNames);
        await Promise.all(
          cacheNames.map((cacheName) => {
            console.log(cacheName);
            return caches.delete(cacheName);
          })
        );
      }
    },
    redirectToProject() {
      const userAgent = navigator.userAgent;
      const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
      // const isMobileDevice = /Mobile/i.test(userAgent) && /Safari/i.test(userAgent);
      // console.log(isMobileDevice);
      if (isMobile) {
        // 如果是移动设备但不是移动浏览器（如iPad上的Safari），则重定向到移动端项目
        window.location.href = "https://m.bubbleplan.cn";
        // window.open('https://m.bubbleplan.cn')
      } else {
        // 如果是PC或其他设备，则重定向到PC端项目
        // window.location.href = 'http://www.bubbleplan.cn';
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

@import "./common.scss";
@import "./styles/font/fonts/stylesheet.css";
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
